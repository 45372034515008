// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.archived-attachment-row {
  display: flex;
  width: 100%;
}

  .archived-attachment-row-name {
    text-transform: capitalize;
    color: var(--color-blue-6);
    cursor: pointer;
    width: 65px;
    margin-right: 12px;
  }

  .archived-attachment-row-carrier {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 12px;
  }

  .archived-attachment-row-timestamp {
    width: 100px;
    margin-right: 12px;
  }`, "",{"version":3,"sources":["webpack://./app/components/ArchivedAttachmentRow/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;AAsBb;;EApBE;IACE,0BAA0B;IAC1B,0BAA0B;IAC1B,eAAe;IACf,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":[".archived-attachment-row {\n  display: flex;\n  width: 100%;\n\n  &-name {\n    text-transform: capitalize;\n    color: var(--color-blue-6);\n    cursor: pointer;\n    width: 65px;\n    margin-right: 12px;\n  }\n\n  &-carrier {\n    width: 200px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    margin-right: 12px;\n  }\n\n  &-timestamp {\n    width: 100px;\n    margin-right: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
