import React, { ReactElement } from 'react';

interface Props {
  tableHeaders: ReactElement;
}

function CTableHeader({ tableHeaders }: Props) {
  return <thead>{tableHeaders}</thead>;
}

export default CTableHeader;
