import React, { ReactElement } from 'react';

interface Props {
  children: ReactElement;
}

function CTableBody({ children }: Props): ReactElement {
  return <tbody>{children}</tbody>;
}

export default CTableBody;
