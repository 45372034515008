import React, { useState, useEffect, SyntheticEvent } from 'react';
import downloadjs from 'downloadjs';
import moment from 'moment-timezone';
import DownloadIcon from '@components/deprecatedTookit/icons/fa/regular/DownloadIcon';
import mimeTypes from 'mime-types';
import { TIME_ZONES } from '@drayalliance/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getImageFileSrc } from '../../utils/data-processing/file';
import { Attachment } from '../../_blessed/components/features/Dispatch/store/attachments/model';
import Image from '../Image/component';

import './style.css';

interface ArchivedAttachmentRowProps {
  attachment: Attachment;
  displayName: string;
  departmentDisplayName: string;
  filename: string;
  file: Attachment['file'];
  // eslint-disable-next-line no-unused-vars
  onNameClick: (...args: any[]) => any;
  // eslint-disable-next-line no-unused-vars
  onGetImage: (...args: any[]) => any;
}

const createDialog = ({
  fileSrc,
  fileType,
  displayName
}: {
  fileSrc: string | null;
  fileType: string;
  displayName: string;
}) => {
  if (!fileSrc) {
    return null;
  }

  return (
    <div className="r-file-thumbnail__dialog">
      <Image fitToScreen alt={displayName} fileSrc={fileSrc} fileType={fileType} />
    </div>
  );
};

function ArchivedAttachmentRow(props: ArchivedAttachmentRowProps) {
  const [fileSrc, setFileSrc] = useState<string | null>(null);
  const { showTimezones } = useFlags();
  const baseClassName = 'archived-attachment-row';
  const className = baseClassName;

  const mimeType = mimeTypes.lookup(props.file.type);
  const isImage = (mimeType && /^image\//.test(mimeType)) ?? false;
  const isPDF = (mimeType && mimeType === 'application/pdf') ?? false;

  const loadComponent = async () => {
    const result = await props.onGetImage(props.file.url);
    const imageFileSrc = await getImageFileSrc(result, mimeType);
    setFileSrc(imageFileSrc);
  };

  const handleDownload = async (event: SyntheticEvent) => {
    event.stopPropagation();

    if (fileSrc) {
      downloadjs(fileSrc, props.filename, mimeType || '');
    }
  };

  const handleNameClick = async (event: SyntheticEvent) => {
    event.stopPropagation();

    if (props.onNameClick) {
      return props.onNameClick(createDialog({ fileSrc, displayName: props.displayName, fileType: props.file.type }));
    }
  };

  useEffect(() => {
    loadComponent();
  }, []);

  let deletedAtMoment = moment(props.attachment.deletedAt);

  // If the showTimezones flag is false, we want to fallback and display the time in PST
  if (!showTimezones) {
    deletedAtMoment = deletedAtMoment.tz(TIME_ZONES.AMERICA_LOS_ANGELES);
  } else {
    deletedAtMoment = deletedAtMoment.tz(moment.tz.guess());
  }

  return (
    <div className={className}>
      <div className={`${baseClassName}-name`} onClick={isPDF || isImage ? handleNameClick : undefined}>
        {props.displayName}
      </div>
      <div className={`${baseClassName}-carrier`}>{props.departmentDisplayName}</div>
      <div className={`${baseClassName}-timestamp`}>
        {deletedAtMoment.format(`MM/DD/YY HH:mm${showTimezones ? ' z' : ''}`)}
      </div>
      <DownloadIcon className="r-file-thumbnail__footer-action-icon" onClick={handleDownload} />
    </div>
  );
}

export default React.memo(ArchivedAttachmentRow);
