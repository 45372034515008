// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chassis-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #0749b7;
  border: 2px solid #0749b7;
  border-radius: 3px;
  font-size: 10px;
  padding: 2px;
  height: 19px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./app/components/ChassisBadge/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,6BAA6B;EAC7B,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".chassis-badge {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: transparent;\n  color: #0749b7;\n  border: 2px solid #0749b7;\n  border-radius: 3px;\n  font-size: 10px;\n  padding: 2px;\n  height: 19px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
