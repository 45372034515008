// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chassisAndChassisPooleEditable__right-sidebar-navigation__content {
      right: -15px;
      top: var(--sidebar-header-height);
      height: auto;
      flex-direction: column;
      background-color: var(--theme-1-bg-color-0);
      z-index: 100;
      padding: 25px;
      transform: translate(0, 28%);
      width: 100%;
      max-width: 600px;
      min-height: 900px;
    }

      .chassisAndChassisPooleEditable__right-sidebar-navigation__content--open {
        transform: translate(0, 19%);
        position: fixed;
      }

      .chassisAndChassisPooleEditable__right-sidebar-navigation__content--close {
        display: none;
      }
    .chassisAndChassisPooleEditable__right-sidebar-navigation__overlay {
      top: var(--sidebar-header-height);
      height: calc(100vh - var(--sidebar-header-height));
      z-index: 100;
    }
`, "",{"version":3,"sources":["webpack://./app/components/ChassisAndChassisPoolsEditable/styles.css"],"names":[],"mappings":"AAEI;MACE,YAAY;MACZ,iCAAiC;MACjC,YAAY;MACZ,sBAAsB;MACtB,2CAA2C;MAC3C,YAAY;MACZ,aAAa;MACb,4BAA4B;MAC5B,WAAW;MACX,gBAAgB;MAChB,iBAAiB;IAUnB;;MARE;QACE,4BAA4B;QAC5B,eAAe;MACjB;;MAEA;QACE,aAAa;MACf;IAGF;MACE,iCAAiC;MACjC,kDAAkD;MAClD,YAAY;IACd","sourcesContent":[".chassisAndChassisPooleEditable {\n  &__right-sidebar-navigation {\n    &__content {\n      right: -15px;\n      top: var(--sidebar-header-height);\n      height: auto;\n      flex-direction: column;\n      background-color: var(--theme-1-bg-color-0);\n      z-index: 100;\n      padding: 25px;\n      transform: translate(0, 28%);\n      width: 100%;\n      max-width: 600px;\n      min-height: 900px;\n\n      &--open {\n        transform: translate(0, 19%);\n        position: fixed;\n      }\n\n      &--close {\n        display: none;\n      }\n    }\n\n    &__overlay {\n      top: var(--sidebar-header-height);\n      height: calc(100vh - var(--sidebar-header-height));\n      z-index: 100;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
