import React, { ReactElement } from 'react';

import TableHeader from './table/header/component';
import TableBody from './table/body/component';

import './styles.css';

interface Props {
  tableHeaders: ReactElement;
  children: ReactElement;
}

function ChassiAndChassisPoolsTable({ tableHeaders, children }: Props): ReactElement {
  const baseClassName = 'chassisAndChassisPoolTable';

  return (
    <table className={`${baseClassName}__table`}>
      <TableHeader tableHeaders={tableHeaders} />
      <TableBody>{children}</TableBody>
    </table>
  );
}

export default React.memo(ChassiAndChassisPoolsTable);
