import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

function Avatar(props) {
  const baseClassName = 'r-avatar';
  let className = baseClassName;

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <div className={className}>
      {props.imagePath ? (
        <img src={props.imagePath} alt="#" className={`${baseClassName}__image`} />
      ) : (
        <p className={`${baseClassName}__text`}>{`${props.firstInitial}${props.lastInitial}`}</p>
      )}
    </div>
  );
}

Avatar.propTypes = {
  className: PropTypes.string,
  imagePath: PropTypes.string,
  firstInitial: PropTypes.string,
  lastInitial: PropTypes.string
};

export default Avatar;
