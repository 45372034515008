// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  min-height: 30px;
  cursor: pointer;
}

  .r-checkbox__disabled {
    cursor: not-allowed;
  }

  .r-checkbox__inner {
    border: solid 1px var(--color-grayscale-4);
    width: 14px;
    height: 14px;
    cursor: inherit;
  }

  .r-checkbox__inner--checked {
      background: var(--color-green-8);
      border-color: var(--color-green-8);
    }

  .r-checkbox__inner-type--circle {
      border-radius: 50%;
    }

  .r-checkbox__inner--right {
      display: flex;
      order: 1;
      margin-left: 8px;
    }

  .r-checkbox__label {
    margin-left: 8px;
    color: var(--color-grayscale-6);
    font-weight: 500;
    font-size: 12px;
  }

  .r-checkbox__label--left {
      display: flex;
      order: 0;
      margin-left: 0;
    }
`, "",{"version":3,"sources":["webpack://./app/components/Checkbox/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,eAAe;AAwCjB;;EAtCE;IACE,mBAAmB;EACrB;;EAEA;IACE,0CAA0C;IAC1C,WAAW;IACX,YAAY;IACZ,eAAe;EAgBjB;;EAdE;MACE,gCAAgC;MAChC,kCAAkC;IACpC;;EAEA;MACE,kBAAkB;IACpB;;EAEA;MACE,aAAa;MACb,QAAQ;MACR,gBAAgB;IAClB;;EAGF;IACE,gBAAgB;IAChB,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;EAOjB;;EALE;MACE,aAAa;MACb,QAAQ;MACR,cAAc;IAChB","sourcesContent":[".r-checkbox {\n  position: relative;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  min-width: 30px;\n  min-height: 30px;\n  cursor: pointer;\n\n  &__disabled {\n    cursor: not-allowed;\n  }\n\n  &__inner {\n    border: solid 1px var(--color-grayscale-4);\n    width: 14px;\n    height: 14px;\n    cursor: inherit;\n\n    &--checked {\n      background: var(--color-green-8);\n      border-color: var(--color-green-8);\n    }\n\n    &-type--circle {\n      border-radius: 50%;\n    }\n\n    &--right {\n      display: flex;\n      order: 1;\n      margin-left: 8px;\n    }\n  }\n\n  &__label {\n    margin-left: 8px;\n    color: var(--color-grayscale-6);\n    font-weight: 500;\n    font-size: 12px;\n\n    &--left {\n      display: flex;\n      order: 0;\n      margin-left: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
