import React from 'react';
import PropTypes from 'prop-types';
import SystemTooltip from '../SystemTooltip';

import './style.css';

function ChassisBadge(props) {
  const { name, color, single } = props;
  const baseClassName = 'chassis-badge';
  let className = baseClassName;

  if (props.className) {
    className += ` ${props.className}`;
  }

  if (!name) {
    return null;
  }

  const style = {};

  if (color) {
    style.color = color;
    style.borderColor = color;
  }

  if (single) {
    style.padding = '0px 3px';
  }

  return (
    <>
      <div className={className} style={style} data-tooltip-id="chassis-badge-icon">
        {single ? name.substring(0, 1) : name}
      </div>
      <SystemTooltip id="chassis-badge-icon" items={[{ message: 'Chassis Pool' }]} />
    </>
  );
}

ChassisBadge.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  single: PropTypes.bool
};

export default ChassisBadge;
