import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import Drawer from '@client/components/Drawer/component';

import './styles.css';

interface Props {
  uuidOrId: string;
  setId: (uuid: string) => void;
  children: ReactElement;
}

export default function ChassisAndChassisPoolsEditable({ uuidOrId, setId, children }: Props): ReactElement {
  const [open, setOpen] = useState(false);
  const baseClassName = 'chassisAndChassisPooleEditable';

  useEffect(() => {
    if (uuidOrId) {
      setOpen(true);
    }
  }, [uuidOrId]);

  const handleClose = (e: ChangeEvent<HTMLDivElement>) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
    }
    setOpen(false);
    setId('');
  };

  return (
    <Drawer
      anchor="left"
      contentClassName={
        open
          ? `${baseClassName}__right-sidebar-navigation__content ${baseClassName}__right-sidebar-navigation__content--open`
          : `${baseClassName}__right-sidebar-navigation__content ${baseClassName}__right-sidebar-navigation__content--close`
      }
      open={open}
      onClose={handleClose}
      persistent
    >
      {children}
    </Drawer>
  );
}
