import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './style.css';

export default function Checkbox(props) {
  // State.
  const [state, setState] = useState({
    checked: props.alwaysChecked || props.checked,
    prevPropsChecked: !!props.checked
  });

  if (!props.alwaysChecked && state.prevPropsChecked !== props.checked) {
    state.prevPropsChecked = props.checked;
    state.checked = props.checked;
  }

  // Event handlers.
  const handleClick = () => {
    if (!props.alwaysChecked) {
      const checkedResult = !state.checked;

      setState({
        ...state,
        checked: checkedResult
      });

      if (props.onToggle) {
        props.onToggle(checkedResult, props);
      }
    }
  };

  let className = 'r-checkbox';
  let classNameInner = 'r-checkbox__inner';
  let labelClassName = 'r-checkbox__label';

  if (props.className) {
    className += ` ${props.className}`;
  }

  if (props.labelAnchor === 'left') {
    labelClassName += ` ${labelClassName}--left`;
    classNameInner += ' r-checkbox__inner--right';
  }

  if (state.checked) {
    classNameInner += ' r-checkbox__inner--checked';
  }

  if (props.variant) {
    classNameInner += ` r-checkbox__inner-type--${props.variant}`;
  }

  if (props.disabled) {
    className += ' r-checkbox__disabled';
  }

  return (
    <div className={className} onClick={props.disabled ? null : handleClick}>
      <div className={classNameInner} />
      {props.label && <label className={labelClassName}>{props.label}</label>}
    </div>
  );
}

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  alwaysChecked: PropTypes.bool,
  labelAnchor: PropTypes.string,
  label: PropTypes.any,
  variant: PropTypes.oneOf(['square', 'circle']),
  onToggle: PropTypes.func,
  id: PropTypes.string, // eslint-disable-line
  dataField: PropTypes.string, // eslint-disable-line
  dataResourceType: PropTypes.string, // eslint-disable-line
  dataId: PropTypes.string // eslint-disable-line
};
