import React, { useState, useEffect, ReactNode } from 'react';
import LoaderSpinner from '../LoadingSpinner';

import './style.css';

type ButtonType = 'button' | 'submit' | 'reset';

export interface IButtonProps {
  // eslint-disable-next-line react/no-unused-prop-types
  id?: any;
  async?: boolean; // button will show spinner as soon as it is clicked
  className?: string;
  style?: object;
  theme?: null | '1' | '2' | '3';
  disabled?: boolean;
  icon?: ReactNode;
  loading?: boolean; // controls when to hide the spinner and reenable the button
  label?: string | any;
  layoutDirection?: 'rtl';
  variant?: 'outlined' | 'text';
  floating?: boolean;
  transparent?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  type?: ButtonType;
  submit?: boolean;
  shape?: 'circle';
  onClick?: any;
  'aria-label'?: string;
  tabIndex?: number;
  testId?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  dataId?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  dataField?: string;
}

function Button(props: IButtonProps) {
  const [loading, setIsLoading] = useState(props.loading);
  const baseClassName = 'Button';
  let className = baseClassName;
  const disabled = props.disabled || loading;

  if (props.layoutDirection === 'rtl') {
    className += ` ${baseClassName}-layout--rtl`;
  }

  if (props.variant) {
    className += ` ${baseClassName}-type-${props.variant}`;

    if (props.disabled || loading) {
      className += ` ${baseClassName}-type-${props.variant}--disabled`;
    } else if (props.theme) {
      className += ` ${baseClassName}-type-${props.variant}--theme-${props.theme}`;
    }
  } else if (props.disabled || loading) {
    className += ` ${baseClassName}--disabled`;
  } else if (props.theme) {
    className += ` ${baseClassName}--theme-${props.theme}`;
  }

  if (props.transparent) {
    className += ` ${baseClassName}--transparent`;
  }

  if (props.floating) {
    className += ` ${baseClassName}--floating`;
  }

  if (props.shape) {
    className += ` ${baseClassName}--${props.shape}`;
  }

  if (props.className) {
    className += ` ${props.className}`;
  }

  const icon = loading ? <LoaderSpinner /> : props.icon;

  const handleClick = (event: any) => {
    if (props.async) {
      setIsLoading(true);

      if (props.onClick) {
        props.onClick(event, props);
      }
    } else if (props.onClick) {
      props.onClick(event, props);
    }
  };

  useEffect(() => {
    setIsLoading(props.loading);
  }, [props.loading]);

  // adding floating to button causes error in console on render
  return (
    <button
      tabIndex={props.tabIndex || 0}
      className={className}
      style={props.style}
      type={props.submit ? 'submit' : 'button'}
      disabled={disabled}
      onClick={handleClick}
      aria-label={props['aria-label'] || ''}
      data-testid={props.testId}
    >
      {icon}
      {props.label && icon && <div className={`${baseClassName}__icon-spacer`} />}
      {props.label}
    </button>
  );
}

export default Button;
