import React, { ReactElement } from 'react';
import ConfirmationDialog from '../ConfirmationDialog/component';
import Modal from '../Modal/component';

import './styles.css';

interface Props {
  open: boolean;
  itemsForDelete: any;
  message: string;
  confirmDelete: () => void;
  cancelDelete: () => void;
}

function ChassisAndChassisPoolDelete({
  open,
  itemsForDelete,
  message,
  confirmDelete,
  cancelDelete
}: Props): ReactElement {
  const baseClassName = 'chassisAndChassisPoolDelete';

  return (
    <Modal className={`${baseClassName}__modal`} open={open} disableClose disableParentScroll>
      <div className={`${baseClassName}__modal-content`}>
        <ConfirmationDialog
          className={`${baseClassName}__confirmation`}
          message={
            <div className={`${baseClassName}__modal-dialogue`}>
              <p>{message}</p>
              <p>{itemsForDelete.join(', ')}</p>
            </div>
          }
          removeConfirmCheckbox
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      </div>
    </Modal>
  );
}

export default ChassisAndChassisPoolDelete;
