// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  background-color: #cddbe1;
  color: white;
  overflow: hidden;
}

  .r-avatar__text {
    font-weight: 600;
    font-size: 18px;
  }

  .r-avatar__image {
    width: 38px;
    height: 38px;
  }
`, "",{"version":3,"sources":["webpack://./app/components/Avatar/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;AAWlB;;EATE;IACE,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,YAAY;EACd","sourcesContent":[".r-avatar {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  border-radius: 50%;\n  width: 38px;\n  height: 38px;\n  background-color: #cddbe1;\n  color: white;\n  overflow: hidden;\n\n  &__text {\n    font-weight: 600;\n    font-size: 18px;\n  }\n\n  &__image {\n    width: 38px;\n    height: 38px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
