// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chassisAndChassisPoolDelete__modal {
    position: fixed !important;
  }
  .chassisAndChassisPoolDelete__modal-content {
    display: flex;
    flex-direction: column;
    background: #fff;
    row-gap: 15px;
    margin: auto;
    justify-content: center;
    align-items: center;
  }
  .chassisAndChassisPoolDelete__modal-dialogue {
    display: block;
    margin: auto;
    font-size: 1.1rem;
    font-weight: 400;
    margin-top: 0;
  }
  .chassisAndChassisPoolDelete__modal-dialogue--p {
      margin: 0;
    }
`, "",{"version":3,"sources":["webpack://./app/components/ChassisAndChassisPoolDelete/styles.css"],"names":[],"mappings":"AACE;IACE,0BAA0B;EAC5B;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;EACrB;EAEA;IACE,cAAc;IACd,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;EAKf;EAHE;MACE,SAAS;IACX","sourcesContent":[".chassisAndChassisPoolDelete {\n  &__modal {\n    position: fixed !important;\n  }\n\n  &__modal-content {\n    display: flex;\n    flex-direction: column;\n    background: #fff;\n    row-gap: 15px;\n    margin: auto;\n    justify-content: center;\n    align-items: center;\n  }\n\n  &__modal-dialogue {\n    display: block;\n    margin: auto;\n    font-size: 1.1rem;\n    font-weight: 400;\n    margin-top: 0;\n\n    &--p {\n      margin: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
